import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import { postAssociateFathomNote } from '../../../store/actions';
import { axiosPostAssociateNotes } from '../../../config/appConfig/axiosInstance';
import "./attendeePopover.scss";


import "./attendeePopover.scss";

const AttendeePopover = ({ data, position, onClose, updateNoteState, isAbove, spinnerVisible }) => {
  const dispatch = useDispatch();
  const { top, left } = position;
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [dropdownList, setDropdownList] = useState({
    companyOptions: [],
    personsOptions: [],
    isAssociatedOptions: [],
  });
  const [selectedRadio, setSelectedRadio] = useState("");
  const [typeaheadOptions, setTypeaheadOptions] = useState([]);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [loading, setLoading] = useState({
    isloadingPage: false,
    openModal: false,
    isSuccess: null,
    message: "",
  });
  const [redirectUrl, setRedirectUrl] = useState({
    noteSavedAffinityLink: "",
    externalLink: ""
  });

  useEffect(() => {
    if(data && Object.keys(data)?.length) {
        let companies = data?.domain_details?.map((company) => {
            return {
                name: company.company_name,
                external_id: Number(company.external_id),
                type: "companies",
            }
        }).filter((company) => company.name && company.external_id);

        let persons = data?.attendee_details?.map((person) => {
                return {
                    name: person?.name,
                    external_id: Number(person?.external_id),
                    type: "persons",
                };
            }) .filter((person) => person.name && person.external_id);
        let isAssociated = [...companies, ...persons];

        setDropdownList({
            ...dropdownList,
            companyOptions: [...companies],
            personsOptions: [...persons],
            isAssociatedOptions: [...isAssociated],
        })
    }

}, [data]);

  useEffect(() => {
    if (selectedRadio === 'company') {
      setTypeaheadOptions(dropdownList?.companyOptions);
    } else if (selectedRadio === 'attendee') {
      setTypeaheadOptions(dropdownList?.personsOptions);
    } else if (selectedRadio === 'associated') {
      setTypeaheadOptions(dropdownList?.isAssociatedOptions);
    }
  }, [selectedRadio]);
  
  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.id);
    setSelectedCompany([]);
  };

  const handleSave = () => {
    setIsActionLoading(true);
    setLoading({ ...loading, isloadingPage: true });
    if (!selectedCompany) {
      console.warn('No option selected.');
      return;
    }

    const company = selectedCompany[0];
    const payload = {
      external_id: company.external_id,
      external_type: company.type,
      fathom_id: Number(data?.fathom_id),
    };

    spinnerVisible();
    dispatch(postAssociateFathomNote(axiosPostAssociateNotes, payload)).then((response) => {
      if (response.status === 'success') {
        setRedirectUrl({ ...redirectUrl, noteSavedAffinityLink: response.data?.hyperlink });
        updateNoteState(true, response.data?.hyperlink); // Pass state to NotesTable
      } else {
        setRedirectUrl({ ...redirectUrl, noteSavedAffinityLink: '' });
        updateNoteState(false, '');
      }
      setLoading({ ...loading, isloadingPage: false, openModal: true });
      setIsActionLoading(false);
    });
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        padding: '18px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 111,
      }}
      className={`attendee-popover ${isAbove ? 'popover-above' : ''}`}
    >
      <div className="sub-title"><b>Associate notes to:</b></div>
      <div className="d-flex mb-3">
        <Form.Check
          type="radio"
          id="company"
          label="Company"
          className="marg-between"
          checked={selectedRadio === "company"}
          onChange={handleRadioChange}
          disabled={dropdownList?.companyOptions?.length === 0}
        />
        <Form.Check
          type="radio"
          id="attendee"
          label="Attendee"
          className="marg-between"
          checked={selectedRadio === "attendee"}
          onChange={handleRadioChange}
          disabled={dropdownList?.personsOptions?.length === 0}
        />
        <Form.Check
          type="radio"
          id="associated"
          label="Already Associated"
          className="marg-between"
          checked={selectedRadio === "associated"}
          onChange={handleRadioChange}
          disabled={dropdownList?.isAssociatedOptions?.length === 0}
        />
      </div>
      <div className="select-company-container">
        <label htmlFor="company-select" className="required-field">
          Select {selectedRadio === 'company' ? "Company" : selectedRadio === 'attendee' ? "Attendee" : selectedRadio === 'associated' ? "Already Associated" : "Option"}
          <span className="select-a-company">*</span>
        </label>
        <Typeahead
          id="company-select"
          options={typeaheadOptions}
          labelKey="name"
          selected={selectedCompany}
          onChange={setSelectedCompany}
          placeholder={`Select a ${selectedRadio === 'company' ? "company" : selectedRadio === 'attendee' ? "attendee" : selectedRadio === 'associated' ? "already associated" : "option"}`}
          disabled={!selectedRadio}
          renderMenu={(results, menuProps) => {
            const companies = results.filter(option => option.type === 'companies');
            const people = results.filter(option => option.type === 'persons');
            return (
              <div {...menuProps} className='typeahead-dropdown'>
                {companies.length > 0 && (
                  <>
                    <div className="category-heading">Company</div>
                    {companies.map(company => (
                      <div key={company.external_id} className="option-item" onClick={() => {
                        setSelectedCompany([company]);
                        document.querySelector('.attendee-popover').dispatchEvent(new MouseEvent("click")); // Example manual closing
                      }}>
                        {company.name}
                      </div>
                    ))}
                  </>
                )}
                {people.length > 0 && (
                  <>
                    <div className="category-heading">People</div>
                    {people.map(person => (
                      <div key={person.external_id} className="option-item" onClick={() => {
                        setSelectedCompany([person]);
                        document.querySelector('.attendee-popover').dispatchEvent(new MouseEvent("click")); // Example manual closing
                      }}>
                        {person.name}
                      </div>
                    ))}
                  </>
                )}
              </div>
            );
          }}
          filterBy={() => true}
        />
      </div>
      <div className="lightDivider mb-2 mt-4"></div>
      <div className="associate-button-container">
        <span className="cancel-button" onClick={onClose}>Cancel</span>
        <span className="save-button" onClick={() => { onClose(); handleSave(); }}>Save</span>
      </div>
    </div>
  );
}

export default AttendeePopover;
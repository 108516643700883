import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector} from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import { useNavigate, useParams, useLocation } from 'react-router-dom';


import NotesTable from "./NotesTable/NotesTable";
import { getNotes, getLoggedInUser, getScopers, getFilteredNotes} from "../../store/actions";
import { axiosGetNotes, axiosUserInfoInstance, axiosScoperInstance } from "../../config/appConfig/axiosInstance";
import LoaderTable from "../../commonComponents/LoadingAnimation/LoaderTable"
import CommonError from "../../commonComponents/CommonError/CommonError";
import { RangePickerWithCustomOptions } from "../../commonComponents/Datepicker/RangePickerWithCustomOptions"
import { getAddress } from "../../utils/getServerAddress";

import NotesDetails from "./NotesDetails/NotesDetails";

import { ReactComponent as SearchIcon } from "../../assets/images/icon_svg/search_icon.svg";
import "./notes.scss"

const Notes = ({}) => {

    const [noteData, userInfo, scopers] = useSelector(({notesReducer, dashBoardReducer, commonReducer}) => [notesReducer.notes, dashBoardReducer?.userInfo, commonReducer.scopers]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [resultSize, setResultSize] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState({
        dates: [],
        isSwitchChecked: false,
        selectedCategory: "all",
        selectedScopers: "",
    })

    const [notesView, setNotesView] = useState("landing");
    const [error, setError] = useState("");
    const [dropdowns, setDropdowns] = useState({
        creator: []
    })

    useEffect(() => {
        setIsLoading(true);
        dispatch(getNotes(axiosGetNotes))
        .then((res) => {
            setIsLoading(false);
            if (res.status === 'failed') {
                setError(res.errMsg || "Failed to fetch notes.");
            }
        })
        .catch((err) => {
            setIsLoading(false);
            setError(err.errMsg || "Failed to fetch notes.");
        });
        if(!userInfo) dispatch(getLoggedInUser(axiosUserInfoInstance));
        if(!scopers?.length) dispatch(getScopers(axiosScoperInstance));
        return () => {};
    }, []);

    useEffect(() => {
        if (id) {
            navigate(`/fathom/${id}`);
            setNotesView('details_view');
        } else {
            // setNotesView('landing');
           
        }
        return () => {
        };
    }, [id, navigate]);

    useEffect(() => { 
        setTableData(noteData);
        setResultSize(noteData?.count);
    }, [noteData]);

    useEffect(() => {
        if (scopers?.data?.length) {
            setDropdowns({
                ...dropdowns,
                creator: [{ full_name: "All", email_address: "all", first_name: "all" }, ...scopers?.data]
            })
        }
    }, [scopers?.data])

    useEffect(() => {
        loadFilteredNotes(filters);
    }, [filters]);

    const handleDateChange = (dates) => {
        if (!dates) {
            setFilters({
                ...filters,
                ['dates'] : [],
            })
            return;
        }
        let start = "", end = "";
        if (dates) {
            [start, end] = dates;
        }
        setFilters({
            ...filters,
            ['dates'] : [start, end],
        })
        setSearchTerm("");
    }

    // Handler for the switch
    const handleSwitchChange = (event) => {
        const { checked } = event.target;
        setFilters({
            ...filters,
            ['isSwitchChecked'] : checked
        })
        setSearchTerm("");
    };

    // // Handler for the dropdown
    const handleScoperChange = (event) => {
        const { value } = event.target;
        setFilters({
            ...filters,
            ['selectedScopers'] : value
        })
        setSearchTerm("");
    };

    const handleSearchChange = (event) => {
        const { value } = event.target;
        let serchCount = tableData?.results?.filter(row =>
            row.meeting_title?.toLowerCase().includes(value.toLowerCase())
        )
        setResultSize(serchCount?.length);
        setSearchTerm(value);
    };
    
    const loadFilteredNotes = (filters) => {   
        setIsLoading(true);
        let params="";
        
        let start = "", end = "";
        if (filters?.dates?.length) {
            [start, end] = filters?.dates;

            params +=  `${params ? "&" : ""}meeting_start_from=${encodeURIComponent(moment(new Date(start).setHours(0, 0, 0, 0)).format())}`
            params +=  `${params ? "&" : ""}meeting_start_to=${encodeURIComponent(moment(new Date(end).setHours(23, 59, 59, 0)).format())}`
        }

        if (filters?.selectedScopers && filters?.selectedScopers !== "all" ) {
            params += `${params ? "&" : ""}scoper=${filters?.selectedScopers}`;
        }
        if(filters?.isSwitchChecked) {
            params += `${params ? "&" : ""}is_associated=${filters?.isSwitchChecked}`;
        }
        
        const url =  `${getAddress()+"/fathom-ai-summaries?"}${params}`
        dispatch(getFilteredNotes(url)).then( resp => {
            setIsLoading(false);
            if (resp.status === "success") {
               
            } else {
                
            }
        })
    }

    const handleRowClick = (data) => {
        navigate(`/fathom/${data.fathom_id}`);
        setNotesView('details_view');
    }

    const renderDetailsView = () => {
        return (
            <NotesDetails onHide={() =>{ setNotesView('landing'); navigate(`/fathom`);} } />
        )
    }

    const handleAssciateClick = (row) => {
        console.log("Associate Clicked:::", row);
    }

    const renderLandingView = () => {
        return (
            <div className="notes-container">
                {error ? (
                <CommonError errorMessage={error} isBlurredRequired={true} />
            ) : (
                <>
                <b className='title'>Fathom</b>
                <div className="utility-container d-flex justify-content-between align-items-center mb-4 mt-3">
                    <div className="notes-search">
                        <Form.Control className="notes-search-box" size="lg" type="text" placeholder="Search" style={{marginRight: "15px", height: "40px", fontSize: "14px", width: "260px", border: "1px solid #1e1b1d" }} onChange={handleSearchChange} value={searchTerm}/>
                        <SearchIcon className="search-icon"/>
                    </div>
                    <div className="filters d-flex justify-content-between align-items-center">
                        <span className="toggleBtn-filter m-r-15">
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Show only unassociated"
                            className="custom-switch"
                            checked={filters?.isSwitchChecked}
                            onChange={handleSwitchChange}
                        />
                        </span>
                        <span className="dropdown-filter m-r-15">
                            <Form.Select aria-label="Default select example" style={{ height: "40px" }}
                                value={filters?.selectedScopers}
                                onChange={handleScoperChange}
                            >
                               {dropdowns?.creator?.map((item, index) => {
                                    return <option key={index} value={item.email_address}>{item.full_name}</option>
                               })}
                            </Form.Select>
                        </span>
                        <span className="date-filter m-r-15">
                            <RangePickerWithCustomOptions selectedDate={filters?.dates} handleDateRangeChange={date => handleDateChange(date)} />
                        </span>
                        {resultSize > 0 && (<span className="result-size"><b>{resultSize}</b> <span className="lightGrey resultText">{resultSize === 1 ? 'result' : 'results'}</span></span>)}
                    </div>
                </div>
                <div className="main-section">
                    {isLoading ? <LoaderTable /> : <NotesTable tableData={tableData} filters={{ ...filters, searchTerm }} handleRowClick={handleRowClick} handleAssciateTrigger={handleAssciateClick}/>}
                </div>
                </>
            )}
            </div>
        );
    }

    return <div className='notes-container'>
    {notesView === "details_view" ? renderDetailsView() : renderLandingView()}
    </div>;
}

export default Notes;
import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {Button} from 'react-bootstrap'
import moment from "moment";
import AttendeePopover from '../AttendeePopover/AttendeePopover';
import { ReactComponent as AffinityIcon } from "../../../assets/images/icon_svg/affinity_new_logo.svg";
import spinnerLoader from "../../../assets/images/spinner.gif";
import "./notesTable.scss"

const NotesTable = ({ tableData, filters, handleRowClick, handleAssciateTrigger }) => {
  const gridRef = useRef(null);
  const popoverRef = useRef(null);
  const [rowData, setrowData] = useState([]);

  const [popoverData, setPopoverData] = useState(null); // To store data for popover
  const [isPopoverVisible, setPopoverVisible] = useState(false); // Track visibility
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [isAbove, setIsAbove] = useState(false);

  const formatDateString = (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const formatDate =
      cellValue && cellValue !== "n/a"
        ? `${moment.utc(cellValue).format("MMM DD, YYYY")}`
        : null;
    const date = cellValue && cellValue !== "n/a" ? moment(cellValue) : null;
    const daysAgo = cellValue ? moment().diff(date, "days") : null;

    return (
      <span>
        {formatDate ? (
          <div>
            <div className="formattedDate" style={{ height: daysAgo ? "20px" : "25px" }}>
              {formatDate}
            </div>
            {daysAgo ? (
              <div className="blueItalic">
                <i>{daysAgo} {daysAgo === 1 ? "day" : "days"} ago</i>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="text-center">-</div>
        )}
      </span>
    );
  };

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };
  
  const handleScrollDebounced = useCallback(debounce(handleScroll, 100), [handleScroll]);
  
  useEffect(() => {
    if (isPopoverVisible) {
      window.addEventListener('scroll', handleScrollDebounced);
    } else {
      window.removeEventListener('scroll', handleScrollDebounced);
    }
  
    return () => {
      window.removeEventListener('scroll', handleScrollDebounced);
    };
  }, [isPopoverVisible, handleScrollDebounced]);

  useEffect(() => {
    if (isPopoverVisible) {
      document.addEventListener('mousedown', handleDocumentClick);
    } else {
      document.removeEventListener('mousedown', handleDocumentClick);
    }

    // Cleanup on unmount
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [isPopoverVisible]);

  const handleDocumentClick = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) 
    ) {
      closePopover();
    }
  };

  const closePopover = () => setPopoverVisible(false);


  const handleButtonClick = (event, rowData) => {
    const buttonRect = event.target.getBoundingClientRect(); // Get button position
    const popoverHeight = 285; // Updated height

    const top = buttonRect.bottom + window.scrollY + 8;
    const left = ((buttonRect.left + buttonRect.width / 2) - 330)

     // Get viewport dimensions
    const viewportHeight = window.innerHeight;

    // Check if the popover would overflow the viewport vertically
    const adjustedTop = top + popoverHeight > viewportHeight + window.scrollY
    ? buttonRect.top + window.scrollY - popoverHeight + 14// Position above the button
    : top;


    setPopoverPosition({
      top: adjustedTop,
      left: left,
    });
    setPopoverData(rowData); // Pass data to popover
    setPopoverVisible(true);
    setIsAbove(adjustedTop !== top);
  };

  const updateNoteState = (saved, link) => {  
    if (saved && popoverData) {
      const updatedRowData = rowData.map((row) =>
        row.fathom_id === popoverData.fathom_id
          ? { ...row, processed_by_orion: true, external_link: link, isLoading: false }
          : row
      );
      setrowData(updatedRowData);
    }
  };

  const UpdateSpinnerVisible = () => {  
      const updatedRowData = rowData.map((row) =>
        row.fathom_id === popoverData.fathom_id
          ? { ...row, isLoading: true }
          : row
      );
      setrowData(updatedRowData);
  };

  const handleAffinityClick = (data) => {
    if (data?.external_link) {
      window.open(data?.external_link, '_blank', 'noopener,noreferrer');
    }
  };

  const renderAssociate = (params) => {
    const isAssociated = params.value;
    const spinnerLoading = params?.data?.isLoading;

    return (
      <span>
        {isAssociated ? (
          <span className="" ref={(ref) => {
            if (!ref) return;
            ref.onclick = (e) => {
              e.stopPropagation();
              handleAffinityClick(params.data);
            };
          }}>
            <AffinityIcon style={{ marginLeft: "6px", height: "20px", width: "24px", cursor: "pointer" }}/>
          </span>
        ) : (
          <Button
            variant="default-outline"
            data-row-id={params.data.fathom_id}
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = (e) => {
                e.stopPropagation();
                handleButtonClick(e, params.data);
              };
            }}
            className="associate-button"
          >
             Associate Note <span> {spinnerLoading && <img src={spinnerLoader} height='18px' width='18px' alt="loader" className="loader-icon" />} </span>
          </Button>
        )}
      </span>
    );
  };

  const [columnDefs, setcolumnDefs] = useState([
    { headerName: 'Meeting Name', field: 'meeting_title', suppressMovable: true, filter: "agTextColumnFilter", minWidth: 340, cellClass: 'meeting-name-cell' },
    { headerName: 'Date of Meeting', field: 'meeting_start_time', cellRenderer: formatDateString, filter: null, },
    { headerName: 'Creator Email', field: 'scoper', filter: "agSetColumnFilter", },
    { headerName: 'No. of Attendees', field: 'attendee_count', filter: "agNumberColumnFilter", },
    { headerName: 'No. of Companies', field: 'company_count', filter: "agNumberColumnFilter", },
    { headerName: 'Associated', field: 'processed_by_orion', filter: "agSetColumnFilter", cellRenderer: renderAssociate },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      sortable: true,
      suppressMenu: false,
      autoHeaderHeight: true,
      unSortIcon: true,
      //   filterParams: { buttons: ["reset"] },
      //   menuTabs: ["filterMenuTab", "columnsMenuTab"],
      resizable: true,
      filter: true,
    };
  }, []);

  useEffect(() => {
    setrowData(tableData?.results);
  }, [tableData]);

  const filteredRowData = useMemo(() => {
    if (!filters.searchTerm) return rowData;

    return rowData.filter(row =>
      row.meeting_title?.toLowerCase().includes(filters.searchTerm.toLowerCase())
    );
  }, [rowData, filters.searchTerm]);

  const handleScroll = () => {
    if (!isPopoverVisible || !popoverData) return;
  
    // Find the associated button element using the row ID
    const buttonElement = document.querySelector(`[data-row-id="${popoverData?.fathom_id}"]`);
    if (!buttonElement) {
      closePopover();
      return;
    }
  
    // Check if the button is visible in the viewport
    const isElementVisible = isInViewport(buttonElement);
    if (!isElementVisible) {
      closePopover();
      return;
    }
  
    // Update popover position
    updatePopoverPosition(buttonElement);
  };
  
  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top < window.innerHeight && rect.bottom > 285 && // Visible vertically
      rect.left < window.innerWidth && rect.right > 0    // Visible horizontally
    );
  };
  
  const updatePopoverPosition = (buttonElement) => {
    const buttonRect = buttonElement.getBoundingClientRect();
    const popoverHeight = 285;
  
    const top = buttonRect.bottom + window.scrollY + 8;
  
    const viewportHeight = window.innerHeight;
  
    const adjustedTop = top + popoverHeight > viewportHeight + window.scrollY
      ? buttonRect.top + window.scrollY - popoverHeight + 14
      : top;

    setPopoverPosition({ top: adjustedTop });
    setIsAbove(adjustedTop !== top);
  };
  
  return (
    <>
      <div className="interactive-area" onMouseDown={(e) => e.stopPropagation()}
      >
       {isPopoverVisible && (
          <div ref={popoverRef}>
            <AttendeePopover
              data={popoverData}
              position={popoverPosition}
              onClose={closePopover}
              updateNoteState={updateNoteState}
              isAbove={isAbove}
              spinnerVisible={UpdateSpinnerVisible}
            />
          </div>
        )}
      </div>
      <div className='notes-table'>
        <div className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            className={`custom-ag-grid ${rowData?.length < 12 ? "smallerHeight" : ""
              }`}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={filteredRowData}
            rowHeight={60}
            onRowClicked={(e) => { handleRowClick(e.data) }}
            onGridReady={(params) => {
              gridRef.current = params.api;
            }}
            onBodyScroll={handleScroll}
          />
        </div>
        <div className='notes-table-pagination'>
        </div>
      </div>
    </>
  );
}

export default NotesTable;
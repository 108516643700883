import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReactComponent as LinkedInSvg } from "../../../assets/images/icon_svg/linkedin.svg";
import { ReactComponent as AffinitySvg } from "../../../assets/images/icon_svg/affinity_new_logo.svg";
import { ReactComponent as CompanyUrlSvg } from "../../../assets/images/icon_svg/url.svg";
import { ReactComponent as UpArrowIcon } from '../../../assets/images/icon_svg/growthUp.svg';
import { ReactComponent as DownArrowIcon } from '../../../assets/images/icon_svg/growthDown.svg';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/icon_svg/growthRight.svg';
import { ReactComponent as SortByIcon } from '../../../assets/images/icon_svg/sort-by.svg';
import { ReactComponent as FoundedYearIcon } from '../../../assets/images/icon_svg/founded_year.svg';
import "./discoverycards.scss";
import Slider from "../Slider/Slider";

const DiscoveryCards = ({ isFilterCollapsed }) => {
  const containerRef = useRef(null);
  const cardRefs = useRef([]);
  const loadDataRef = useRef(null)
  const [filteredData] = useSelector(({ discoveryReducer }) => [discoveryReducer.discoveryCompanies]);
  const [isSliderOpen, setSliderOpen] = useState(true);
  const [sliderContent, setSliderContent] = useState(filteredData?.results?.[0]?.company_domain || null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState({});
  const [sortedAndFilteredData, setSortedAndFilteredData] = useState([]);
  const [filterSortState, setFilterSortState] = useState({
    sortCriteria: "",
    filterTpStatus: null,
    filterTpOwner: null,
  });
  const [focusedCardIndex, setFocusedCardIndex] = useState(0);
  const [visibleData, setVisibleData] = useState([]); // Initially empty
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const BATCH_SIZE = 50;
  const SCROLL_THRESHOLD = 0.5;

  useEffect(() => {
    let data = [...(filteredData?.results || [])];
  
    if (filterSortState.filterTpStatus) {
      data = data.filter(
        (company) => company.tp_status === filterSortState.filterTpStatus
      );
    }
    if (filterSortState.filterTpOwner) {
      data = data.filter((company) =>
        company.tp_owners?.includes(filterSortState.filterTpOwner)
      );
    }
  
    data.sort((a, b) => {
      if (filterSortState.sortCriteria === "Company Name") {
        return a.company_name.localeCompare(b.company_name);
      }
      if (filterSortState.sortCriteria === "TP Status") {
        return (a.tp_status || "").localeCompare(b.tp_status || "");
      }
      if (filterSortState.sortCriteria === "TP Owners") {
        return (a.tp_owners?.[0] || "").localeCompare(b.tp_owners?.[0] || "");
      }
      return 0;
    });
  
    setSortedAndFilteredData(data);
    setVisibleData(data.slice(0, Math.min(BATCH_SIZE, data.length)));
    setOffset(Math.min(BATCH_SIZE, data.length));
  }, [filterSortState, filteredData]);

  const useDebounce = (callback, delay) => {
    const [debounceTimer, setDebounceTimer] = useState(null);
  
    return (...args) => {
      if (debounceTimer) clearTimeout(debounceTimer);
      setDebounceTimer(setTimeout(() => callback(...args), delay));
    };
  };

  const loadMoreData = () => {
    if (loading) return;
    if (offset >= sortedAndFilteredData.length) return; 
    
    setLoading(true);
    setTimeout(() => {
      const nextBatch = sortedAndFilteredData.slice(offset, offset + BATCH_SIZE);
      setVisibleData((prevData) => [...prevData, ...nextBatch]);
      setOffset((prevOffset) => prevOffset + BATCH_SIZE);
      setLoading(false);
    }, 50);
  };
  
  const debouncedLoadMoreData = useDebounce(loadMoreData, 50); 

  const handleScroll = () => {
    if (!loadDataRef.current) return;
  
    const container = loadDataRef.current;
    const containerHeight = container.offsetHeight;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
  
    if (
      scrollTop + containerHeight >= scrollHeight - 100 && 
      offset < sortedAndFilteredData.length
    ) {
      debouncedLoadMoreData();
    }
  };

  useEffect(() => {
    const container = loadDataRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    };
  
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, offset, debouncedLoadMoreData]);

  const handleSortChange = (criteria) => {
    setFilterSortState((prev) => ({ ...prev, sortCriteria: criteria }));
  };

  const handleFilterTpStatusChange = (status) => {
    setFilterSortState((prev) => ({ ...prev, filterTpStatus: status }));
  };

  const handleFilterTpOwnerChange = (owner) => {
    setFilterSortState((prev) => ({ ...prev, filterTpOwner: owner }));
  };

  const uniqueTpStatuses = Array.from(
    new Set(filteredData?.results?.map(company => company.tp_status).filter(Boolean))
  );

  const uniqueTpOwners = Array.from(
    new Set(
      filteredData?.results?.flatMap(company => company.tp_owners).filter(Boolean)
    )
  );

  const handleKeyDown = (e) => {
    if (!sortedAndFilteredData.length) return;
  
    let newIndex = focusedCardIndex;
    const totalCards = sortedAndFilteredData.length;
  
    if (e.key === "ArrowDown" || e.key === "ArrowRight") {
      newIndex = Math.min(focusedCardIndex + 1, totalCards - 1);
      e.preventDefault();
    } else if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
      newIndex = Math.max(focusedCardIndex - 1, 0);
      e.preventDefault();
    } else if (e.key === "Enter") {
      const focusedCompany = sortedAndFilteredData[focusedCardIndex];
      if (focusedCompany) handleCardClick(focusedCompany.company_domain, focusedCardIndex);
      return;
    }
  
    setFocusedCardIndex(newIndex);
    scrollToCard(newIndex);
  };

  const handleNextCard = () => {
    if (focusedCardIndex < sortedAndFilteredData.length - 1) {
      const nextIndex = focusedCardIndex + 1;
      setFocusedCardIndex(nextIndex);
      const nextCompany = sortedAndFilteredData[nextIndex];
      setSliderContent(nextCompany.company_domain);
      scrollToCard(nextIndex);
    }
  };
  
  const handlePrevCard = () => {
    if (focusedCardIndex > 0) {
      const prevIndex = focusedCardIndex - 1;
      setFocusedCardIndex(prevIndex);
      const prevCompany = sortedAndFilteredData[prevIndex];
      setSliderContent(prevCompany.company_domain);
      scrollToCard(prevIndex);
    }
  };
  
  const scrollToCard = (index) => {
    const card = cardRefs.current[index];
    if (card) {
      card.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [sortedAndFilteredData, focusedCardIndex]);

  useEffect(() => {
    const card = cardRefs.current[focusedCardIndex];
    if (card) {
      card.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [focusedCardIndex]);

  const formatNumber = (number) => {
    if (number === "n/a") {
      return "n/a";
    }
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(2) + "B";
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(2) + "M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(2) + "K";
    } else {
      return number.toString();
    }
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const GrowthIcon = ({ value }) => {
    if (value > 0) return <UpArrowIcon />;
    if (value < 0) return <DownArrowIcon />;
    return <RightArrowIcon />;
  };

  const CompanyGrowth = ({ growth_1m, growth_6m, growth_1y, growth_2y }) => {
    // Check if there's at least one valid growth value to display the section
    const hasValidGrowthData = [growth_1m, growth_6m, growth_1y, growth_2y].some(value => value !== null && value !== 'n/a');

    if (!hasValidGrowthData) return null; // Hide the section if no valid data

    const growthData = [
      { label: '1 Month', value: growth_1m },
      { label: '6 Months', value: growth_6m },
      { label: '1 Year', value: growth_1y },
      { label: '2 Years', value: growth_2y },
    ];

    return (
      <div className='growth-sec d-flex flex-wrap mb-2 justify-content-around'>
        {growthData.map(({ label, value }, index) => (
          <span key={index} className='growth-box d-inline-block'>
            <div className='growth-value d-flex align-items-center justify-content-center'>
              <span className='growth-icon'>
                <GrowthIcon value={value} />
              </span>
              <span>
                <b className='growth-val'>
                  {value !== null && value !== 'n/a' ? `${value}%` : 'N/A'}
                </b>
              </span>
            </div>
            <div className='growth-text'>{label}</div>
          </span>
        ))}
      </div>
    );
  };

  const handleCardClick = (companyDomain, index) => {
    setSliderContent(companyDomain);
    setSliderOpen(true);
    setFocusedCardIndex(index);
  };

  const handleCloseSlider = () => {
    setSliderOpen(false);
    setFocusedCardIndex(null)
  };

  const handleLogoClick = (url) => {
    const fullUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://www.${url}`;
    window.open(fullUrl, '_blank');
  };

  const truncateDescription = (description) => {
    if (!description) return '';
    return description.length > 150 ? `${description.slice(0, 60)}...` : description;
  };

  const handleDescriptionToggleView = (index) => {
    setIsDescriptionExpanded(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <div className="discovery-cards" style={{ margin: '0px' }}>
      <div className={`discovery-company-data ${isSliderOpen ? "discovery-slider-open" : ""}`} ref={loadDataRef}>
        <div className={`discovery-company-container ${isSliderOpen && isFilterCollapsed ? "only-slider-open" : ""} ${!isSliderOpen && isFilterCollapsed ? "discovery-both-closed" : ""}  ${!isSliderOpen && !isFilterCollapsed && visibleData?.length > 2 ? "discovery-filter-open-slider-closed" : ""}`} ref={containerRef}>
          <div className="d-flex justify-content-between align-items-center mb-3 table-count">
            {(filteredData?.total_count > 0 || sortedAndFilteredData.length > 0) && (
              <div>
                Showing <b>{sortedAndFilteredData.length}</b> results
              </div>
            )}
            <Dropdown>
              <Dropdown.Toggle className="custom-dropdown-button"><SortByIcon /> Sort by</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSortChange("Company Name")}>
                  Company Name
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSortChange("TP Status")}>
                  TP Status
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSortChange("TP Owners")}>
                  TP Owners
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="d-flex flex-wrap gap-2 " style={{marginBottom: '75px'}}>
            {visibleData?.map((company, index) => (
              <div key={index}  ref={(el) => (cardRefs.current[index] = el)} className="card-wrapper">
                <div className={`outer-card ${index === focusedCardIndex ? "focused-card" : ""}`} onClick={() => handleCardClick(company.company_domain, index)} style={{width: '426px'}}>
                  <div className="inner-content" >
                    <div className="company-card">
                      <div className="company-header d-flex justify-content-between align-items-center">
                        <div className="company-name">{company.company_name}</div>
                        <div className="company-logos">
                          {company?.company_domain && company?.company_domain !== 'n/a' && (
                            <span className="company-logo-img" onClick={() => handleLogoClick(company.company_domain)}>
                              <CompanyUrlSvg />
                            </span>
                          )}
                          {company?.url && company?.url !== 'n/a' && (
                            <span className="company-logo-img" onClick={() => handleLogoClick(company.url)}>
                              <AffinitySvg style={{ marginLeft: "6px" }} />
                            </span>
                          )}
                          {company?.linkedin_url && company?.linkedin_url !== 'n/a' && (
                            <span className="company-logo-img" onClick={() => handleLogoClick(company.linkedin_url)}>
                              <LinkedInSvg />
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="tp-info">
                        <div className="tp-status">
                          <i>TP Status: </i>
                          <span className="tp-status-value">{company.tp_status?.toUpperCase() || 'N/A'}</span>
                        </div>
                        <div className="tp-owners">
                          <i>TP Owners: </i>
                          {company.tp_owners?.map((owner, idx) => (
                            <span key={idx} className="tp-owner-value">{owner}</span>
                          ))}
                        </div>
                      </div>
                      <div className="lightdivider"></div>
                      <div className="year-founded"><span><FoundedYearIcon className='founded-year-icon'/><i className="company-year-founded">Founded year: </i> <strong className="year-founded-data">{company?.year_founded || 'n/a'}</strong></span></div>
                      <div className={`growth-funding-block ${!CompanyGrowth({ ...company }) ? "no-growth-data" : ""}`}>
                        <div className="growth-section">
                          <CompanyGrowth {...company} />
                        </div>
                        <div className="funding-info mb-2">
                          <div>
                            <strong>Total Funded:</strong> {company.total_funding_usd === "n/a" ? "n/a" : `$${formatNumber(company.total_funding_usd)}`}
                          </div>
                          <div>
                            <strong>Last Funded On:</strong>{" "}
                            {company.last_funding_on === "n/a" || !isValidDate(company.last_funding_on)
                              ? "n/a"
                              : formatDate(company.last_funding_on)}
                          </div>
                        </div>
                      </div>
                      <div className="desc mb-2">
                        {company?.description && company?.description !== n / a && (
                          <span className="description-text">
                            {isDescriptionExpanded[index] ? company.description : truncateDescription(company.description)}
                            <span className="view-more-description" onClick={() => handleDescriptionToggleView(index)}>
                              {isDescriptionExpanded[index] ? ' View Less' : 'View More'}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="slider-container">
        {isSliderOpen && (
          <Slider isOpen={isSliderOpen} onClose={handleCloseSlider} companyDomain={sliderContent} onNext={handleNextCard} onPrev={handlePrevCard} isNextDisabled={focusedCardIndex === sortedAndFilteredData.length - 1} isPrevDisabled={focusedCardIndex === 0} />
        )}
      </div>
    </div>
  );
};

export default DiscoveryCards;

import React, { useEffect, useState} from 'react'
import {createRoot} from 'react-dom/client'
import moment from "moment"
import * as amplitude from '@amplitude/analytics-browser';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Form, Button} from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import {isWithinTokenLimit} from 'gpt-tokenizer'
// import utility functions.
import { getScopers, getFunction, getRefHeaderFn, getIncompleteFn, searchAllReferences, getTrendingProjects } from "../../store/actions";
import { axiosScoperInstance, axiosReferenceSearchInstance, axiosGetFunction, axiosGetRefHeader, axiosGetIncomplete, axiosAllReference } from "../../config/appConfig/axiosInstance";
import { useCancelRequests } from "../../customHooks";
import { RangePickerWithCustomOptions } from "../../commonComponents/Datepicker/RangePickerWithCustomOptions"
import { removeUnderScore } from '../../utils/removeUnderScore';
import { addOrRemoveAllFromDropdowns, addOrRemoveAllFromScopersDropdowns, } from '../../utils';
import { getAddress } from "../../utils/getServerAddress";
// import component.
import SubmitCard from "../../commonComponents/SubmitCard/SubmitCard";

import { LoadingBlock } from "../../commonComponents/LoadingAnimation/LoaderComponents";
import LoaderTable from "../../commonComponents/LoadingAnimation/LoaderTable"
import NoFilter from "../../commonComponents/NoFilter/NoFilter";
import CommonError from "../../commonComponents/CommonError/CommonError";
import SideNavbar from './ConversationDetails';
import { ConversationTable } from './conversationTable';
import ReferencePersonModal from './ReferencePersonModal';
import DataTable from "./DataTable";
// import svg.
import { ReactComponent as AllReferencesSvg } from "../../assets/images/icon_svg/allReferences.svg";
import { ReactComponent as IncompleteReferencesSvg } from "../../assets/images/icon_svg/incompleteReferences.svg";
import { ReactComponent as TrendingSvg } from "../../assets/images/icon_svg/trending.svg";
import { ReactComponent as ChatGPTSvg } from "../../assets/images/icon_svg/chatgpt.svg";
// import scss and stlyes.
import "react-datepicker/dist/react-datepicker.css";
import "./references.scss"
import 'react-bootstrap-typeahead/css/Typeahead.css';

let apiCalled = true;

const References = () => {
    useCancelRequests(axiosReferenceSearchInstance, axiosGetFunction);
    const dispatch = useDispatch();
    const [refSearchData, refSearchDataGPT, refDropdown, refHeader, incompleteRef, trendingReferences] = useSelector(({ referenceReducer }) => [referenceReducer.searchResult?.results, referenceReducer.searchResult?.gpt, referenceReducer?.refDropdown, referenceReducer?.refHeader, referenceReducer.incompleteRef?.results, referenceReducer.trendingReferences?.results])
    const [scopers] = useSelector(({ commonReducer }) => [commonReducer.scopers])
    const [peopleInvolved, setPeopleInvolved] = useState({
        modal: false,
        content: {},
        rowIndex: null,
        itemIndex: null
    })
    const [filteredData, setFilteredData] = useState({
        rows: [],
        isfilterApplied: false
    })
    const [refRowModal, setRefRowModal] = useState({
        showModal: false,
        modalContent: {},
        rowIndex: null,
    })
    const [toast, setToast] = useState({
        show: false,
        isSuccess: "",
        message: "",
    })

    const [view, setView] = useState({
        viewType: "listView",
        isLoading: false,
        isError: false,
        errorMessage: "",
        btnFlag: false,
        
    });
    const [isHeaderLoading, setIsHeaderLoading] = useState(false)
    const [selectedRef, setSelectedRef] = useState('all')

    const [form, setForm] = useState({
        startDate: moment(new Date().setHours(0, 0, 0, 0)),
        endDate: moment(new Date().setHours(23, 59, 59, 0)),
        dates: [],
        selectedReferenceArr: [{ display_name: "All", server_value: "all" }],
        selectedFunctionArr: [{ display_name: "All", server_value: "all" }],
        selectedRatingsArr: [{ display_name: "All", server_value: "all" }],
        selectedProjects: [{ display_name: "All", server_value: "all" }],
        selectedScoperArr: [{ full_name: "All", email_address: "all", first_name: "all" }],
        conversation_type: "All",
        ratings: "5",
        errors: {},
        endDateIndex: null,
        isViewMoreLoading: false,
        isViewMoreBtnDisable: false,
        selectedDates: "today"
    });

    const [dropdowns, setDropdowns] = useState({
        functions: [],
        references_types: [],
        ratings: [],
        projects: [],
        creator: []
    })

    const [legend, setLegend] = useState({})
    const [rowData, setRowData] = useState([])
    const [rowDataGPT, setRowDataGPT] = useState([])

    useEffect(() => {
        if (refDropdown && Object.keys(refDropdown).length) {
            setDropdowns({
                ...dropdowns,
                functions: [{ display_name: "All", server_value: "all" }, ...refDropdown?.functions],
                references_types: [{ display_name: "All", server_value: "all" }, ...refDropdown?.reference_types],
                ratings: [{ display_name: "All", server_value: "all" }, ...refDropdown?.ratings],
                projects: [{ display_name: "All", server_value: "all" }, ...refDropdown?.projects],
            })
        }

    }, [refDropdown])

    useEffect(() => {
        if (scopers?.data?.length) {
            setDropdowns({
                ...dropdowns,
                creator: [{ full_name: "All", email_address: "all", first_name: "all" }, ...scopers?.data]
            })
        }

    }, [scopers?.data])

    useEffect(() => {
        dispatch(getScopers(axiosScoperInstance));
        dispatch(getFunction(axiosGetFunction));
        setIsHeaderLoading(true);
        dispatch(getRefHeaderFn(axiosGetRefHeader)).then(res => {
            setIsHeaderLoading(false)
        });
        setView({
            ...view,
            isLoading: true,
            isError: false,
            errorMessage: "",
        })
        loadReferenceList();
        amplitude.logEvent(`Conversations Loaded`);
    }, [])


    useEffect(() => {
        if(selectedRef && !apiCalled) {
            if (selectedRef === "incomplete") {
                setView({
                    ...view,
                    isLoading: true,
                    isError: false,
                    errorMessage: "",
                })
                dispatch(getIncompleteFn(axiosGetIncomplete)).then((res) => {
                    apiCalled = true;
                    if(res?.status === "success") {
                        setView({
                            ...view,
                            isLoading: false,
                            isError: false,
                            errorMessage: "",
                        })
                        if(res?.data?.length === 0) {
                            getRows([])
                        }
                    } else {
                        setView({
                            ...view,
                            isLoading: false,
                            isError: true,
                            errorMessage: res?.errMsg || "Sorry, something went wrong. Please try again later.",
                        })
                    }
                    
                });
            } else if (selectedRef === "trending") {
                setView({
                    ...view,
                    isLoading: true,
                    isError: false,
                    errorMessage: "",
                })
                loadTrendingList()
            } else {
                setView({
                    ...view,
                    isLoading: true,
                    isError: false,
                    errorMessage: "",
                })
                loadReferenceList();
            }
            dispatch(getRefHeaderFn(axiosGetRefHeader))
        }
    }, [selectedRef])

    useEffect(() => {
            getRows(refSearchData)
    }, [refSearchData])

    useEffect(() => {
            getRows(trendingReferences)
    }, [trendingReferences])

    useEffect(() => {
            getRows(incompleteRef)
    }, [incompleteRef])

    useEffect(() => {
        setRowDataGPT(refSearchDataGPT)
    }, [refSearchDataGPT])

    const rearrangeSeq = (originalObject) => {
        const customOrder = ["content", "creator", "all_projects", "people", "all_functions", "all_reference_types", "in_reference_contacts", "created_at"];
        const reorderedObject = customOrder.reduce((obj, key) => {
            if (originalObject?.hasOwnProperty(key)) {
                obj[key] = originalObject[key];
            }
            return obj;
        }, {});
        return reorderedObject
    }

    const getRows = (data) => {
        if (data?.length) {
            let headers = rearrangeSeq(data[0])
            let final = {}
            for (let key in headers) {
                final[key] = removeUnderScore(key)
            }
            setLegend({
                ...final
            })
            setRowData([
                ...data
            ])
        } else {
            setLegend([])
            setRowData([])
        }
    }    

    const defaultAll = (arr) => arr?.some(item => item?.server_value === "all" || item?.email_address === "all")

    const loadTrendingList = () => {
        let lastWeekdate = moment(new Date()).subtract(7, 'days')
        let reqPayload = {
            start_time: moment(new Date(lastWeekdate).setHours(0, 0, 0, 0)).format(),
            end_time: moment(new Date()).format(),
            projects: String(refHeader?.trending_project?.project) || ""
        }
        dispatch(getTrendingProjects(axiosAllReference, reqPayload)).then((res) => {
            apiCalled = true;
            if (res?.status === "success") {
                setView({
                    ...view,
                    isLoading: false,
                    isError: false,
                    errorMessage: "",
                })
                if (res?.data?.length === 0) {
                    getRows([])
                }
            } else {
                setView({
                    ...view,
                    isLoading: false,
                    isError: true,
                    errorMessage: res?.errMsg || "Sorry, something went wrong. Please try again later.",
                })
            }
        })
    }

    const loadReferenceList = () => {
        if (isInvaild()) {
            return
        }
        let start_time = "", end_time="", params="";
        if (form?.selectedDates) {
            if (form?.selectedDates === "today") {
                start_time = `start_time=${encodeURIComponent(moment(new Date().setHours(0, 0, 0, 0)).format())}`
                end_time = `end_time=${encodeURIComponent(moment(new Date()).format())}`
            } else if (form?.selectedDates === "lastWeek") {
                let lastWeek = moment().subtract(7, 'days');
                const startOfPreviousWeek = lastWeek.startOf('week').day(1)
                start_time = `start_time=${encodeURIComponent(moment(new Date(startOfPreviousWeek).setHours(0, 0, 0, 0)).format())}`
                const endOfPreviousWeek = startOfPreviousWeek.clone().day(7);
                end_time = `end_time=${encodeURIComponent(moment(new Date(endOfPreviousWeek).setHours(23, 59, 59, 0)).format())}`
            } else if (form?.selectedDates === "lastMonth") {
                const previousMonth = moment().subtract(1, 'months');
                const startOfPreviousMonth = previousMonth.startOf('month');  
                start_time = `start_time=${encodeURIComponent(moment(new Date(startOfPreviousMonth).setHours(0, 0, 0, 0)).format())}`
                const endOfPreviousMonth = previousMonth.endOf('month');
                end_time = `end_time=${encodeURIComponent(moment(new Date(endOfPreviousMonth).setHours(23, 59, 59, 0)).format())}`
            } else if (form?.selectedDates === "all") {
                start_time = `start_time=${encodeURIComponent(moment(new Date('2015-01-01').setHours(0, 0, 0, 0)).format())}`
                end_time = `end_time=${encodeURIComponent(moment(new Date()).format())}`
            } else {
                start_time = `start_time=${encodeURIComponent(moment(new Date(form.startDate).setHours(0, 0, 0, 0)).format())}`
                end_time = `end_time=${encodeURIComponent(moment(new Date(form?.endDate)).format())}`
            }
        } else {
            start_time = `start_time=${encodeURIComponent(moment(new Date(form.startDate).setHours(0, 0, 0, 0)).format())}`
            end_time = `end_time=${encodeURIComponent(moment(new Date(form?.endDate).setHours(23, 59, 59, 0)).format())}`
        }

        let projectVal = form[`selectedProjects`]?.length && !defaultAll(form[`selectedProjects`]) ? form[`selectedProjects`]?.map(function (item) {
            return item['server_value'];
        }) : null
        let scoperVal = form[`selectedScoperArr`]?.length && !defaultAll(form[`selectedScoperArr`]) ? form[`selectedScoperArr`]?.map(function (item) {
            return item['email_address'];
        }) : null

        let referenceType = form[`selectedReferenceArr`]?.length && !defaultAll(form[`selectedReferenceArr`]) ? form[`selectedReferenceArr`]?.map(function (item) {
            return item['server_value'];
        }) : null
        let ratingVal = form[`selectedRatingsArr`]?.length && !defaultAll(form[`selectedRatingsArr`]) ? form[`selectedRatingsArr`]?.map(function (item) {
            return Number(item['server_value']);
        }) : null
        let functionVal = form[`selectedFunctionArr`]?.length && !defaultAll(form[`selectedFunctionArr`]) ? form[`selectedFunctionArr`]?.map(function (item) {
            return item['server_value'];
        }) : null
        
        params = scoperVal?.length ? `${scoperVal?.length ? params + "&" : null}` + scoperVal.map(param => `creators=${param}`).join('&') : params
        params = functionVal?.length ? `${functionVal?.length ? params + "&" : null}` + functionVal.map(param => `functions=${param}`).join('&') : params
        params = projectVal?.length ? `${projectVal?.length ? params + "&" : null}` + projectVal.map(param => `projects=${param}`).join('&') : params
        params = referenceType?.length ?`${referenceType?.length ? params + "&" : null}` + referenceType.map(param => `reference_types=${param}`).join('&') : params
        params = ratingVal?.length ? `${ratingVal?.length ? params + "&" : null}` + ratingVal.map(param => `ratings=${param}`).join('&') : params
        
        const url =  `${getAddress()+"/conversations/all?"}${start_time+"&"+end_time}${params}`
        dispatch(searchAllReferences(url)).then( resp => {
            apiCalled = true;
            if (resp.status === "success") {
                setView({
                    ...view,
                    isError: false,
                    errorMessage: "",
                    isLoading: false,
                    btnFlag: false,
                })
                if(resp?.data?.length === 0) {
                    getRows([])
                }
            } else {
                setView({
                    ...view,
                    isError: true,
                    errorMessage: resp?.errMsg || "Sorry, something went wrong. Please try again later.",
                    isLoading: false,
                    btnFlag: false,
                    endDateIndex: null
                })
            }
        })
    }

    const isInvaild = () => {
        let isError = false;;
        let errors = { ...form.errors };
        if ((!form.dates[0] || !form.dates[1]) && !form.selectedDates) {
            isError = true
            errors["dates"] = "Please select dates";
        }
        setForm({
            ...form,
            errors: errors
        })
        return isError
    }

    const handleDateChange = (dates) => {
        let isError = { ...form.errors };
        let start = "", end = "";
        if (dates) {
            [start, end] = dates;
        }
        isError["dates"] = (!start && !end) ? "Please select dates" : "";
        setForm({
            ...form,
            ["dates"]: [start, end],
            startDate: start,
            endDate: end,
            errors: isError,
            selectedDates: dates?.length ? "" : form?.selectedDates
        })
        const formattedStart = start ? moment(start).format("DD-MM-YYYY") : null;
        const formattedEnd = end ? moment(end).format("DD-MM-YYYY") : null;
        amplitude.logEvent("Conversation Time Filter Clicked", { selected_time_filter: "Custom Date Range", start_date: formattedStart, end_date: formattedEnd });
        apiCalled = false;
    }

    const handleModal = (event) => {
        setRefRowModal({
            ...refRowModal,
            showModal: true,
            modalContent: event?.data,
            rowIndex: event?.rowIndex
        })
    }

    const handleDropdowns = (value, name) => {
        setForm({
            ...form,
            [`${name}`]: name === `selectedScoperArr` ? addOrRemoveAllFromScopersDropdowns(value, `${name}`, form) : addOrRemoveAllFromDropdowns(value, `${name}`, form),
        })
        apiCalled = false;
        const filterName = name.replace(/selected(\w+)Arr/, '$1');
        amplitude.logEvent('Conversation Filter Clicked', { selected_filter: filterName });
    }

    const handleReferenceClick = (val) => {
        let isError = { ...form.errors };
        isError["dates"] = !val ? "Please select dates" : "";
        setForm({
            ...form,
            selectedDates: val,
            dates: [],
            errors: isError,
        })
        const timeFilterDisplayValues = {
            today: "Today",
            lastWeek: "Last Week",
            lastMonth: "Last Month",
            all: "All"
        };
        const displayValue = timeFilterDisplayValues[val];
        amplitude.logEvent(`Conversation Time Filter Clicked`, { selected_time_filter: displayValue });
        apiCalled = false;
    }

    const handlePeoplesClicks = (props, rowIndex, itemIndex) => {
        setPeopleInvolved({
            ...peopleInvolved,
            modal: true,
            content: props,
            rowIndex: rowIndex,
            itemIndex: itemIndex, 
        })
    }

    useEffect(() => {
        if (form?.selectedFunctionArr?.length && !apiCalled) {
            setView({
                ...view,
                isLoading: true,
                isError: false,
                errorMessage: "",
            })
            loadReferenceList()
        }
    }, [form?.selectedFunctionArr, form?.selectedProjects, form?.selectedRatingsArr, form?.selectedReferenceArr, form?.selectedScoperArr, form?.selectedDates, form?.dates])

    const handleHeaderClicks = (e, val) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedRef(val)
        apiCalled = false
        amplitude.logEvent(`${val?.slice(0, -1)?.charAt(0)?.toUpperCase() + val?.slice(1)?.toLowerCase()} Reference Tab Clicked`);
    }

    const refreshPageData = () => {
        setTimeout(()=>{
            dispatch(getRefHeaderFn(axiosGetRefHeader))
            selectedRef === "all" ? loadReferenceList() : selectedRef === "incomplete" ? dispatch(getIncompleteFn(axiosGetIncomplete)) : selectedRef === "trending" ? loadTrendingList() : loadReferenceList()
        },1500)
    }

    const openTableInNewTab = () => {
        const newTab = window.open('', '_blank');
        newTab.document.body.innerHTML = `<html><head><title>Data Table</title><link rel="stylesheet" href="../../src/component/References/dataTable.css"></head><body><div style="padding: 30px 10px;"><h1> References (${filteredData?.isfilterApplied ? filteredData?.rows?.length : rowData?.length}) </h1><div id="table-container"></div></div></body></html>`;
        const tableContainer = newTab.document.getElementById('table-container');
        tableContainer.appendChild(document.createElement('div'));
        const root = createRoot(tableContainer.firstChild);
        root.render(
            <DataTable data={filteredData?.isfilterApplied ? filteredData?.rows :rowData} headers={legend}/>
          );
    }

    const handledFilteredRow = (rows, isAnyFilteredApplied) => {
        setFilteredData({
            ...filteredData,
            isfilterApplied: isAnyFilteredApplied,
            rows: rows
        })
    }

    const handleChatGPT = () => {
        const withinTokenLimit = isWithinTokenLimit(rowDataGPT, 8192)
        if(withinTokenLimit === false) {
            setToast({
                ...toast,
                show: true,
                isSuccess: false,
                message: "Current size exceeds GPT4 result size. Please try adding more filters"
            })
            return
        }
        navigator.clipboard.writeText(rowDataGPT)
        setToast({
            ...toast,
            show: true,
            isSuccess: true,
            message: "Current response is copied to clipboard!"
        })
        setTimeout (() => {
            window.open('https://chat.openai.com', '_blank');
            amplitude.logEvent(`ChatGPT Clicks`);
        }, 2000) 
        
    }

    return (
        <>
            <Row className='m-0 conversation-box'>
                <Col>
                    {toast?.show && <SubmitCard showToast={toast?.show} onHide={() => setToast({...toast, show: false, isSuccess: null, message: ""})} isSuccess={toast?.isSuccess} message={toast?.message} />}
                    {peopleInvolved?.modal && <ReferencePersonModal show={peopleInvolved.modal} onHide={() => setPeopleInvolved({ ...peopleInvolved, modal: false, content:{}, rowIndex:null, itemIndex:null })} content={peopleInvolved?.content} selectedRowIndex={peopleInvolved?.rowIndex} selectedItemIndex={peopleInvolved?.itemIndex} selectedRefType={selectedRef} callbackFn={refreshPageData}/>}
                    {refRowModal?.showModal && <SideNavbar show={refRowModal?.showModal} onHide={() => setRefRowModal({ ...refRowModal, showModal: false, modalContent:{}, rowIndex:null })} modalData={refRowModal?.modalContent} selectedRefType={selectedRef} selectedRowIndex={refRowModal?.rowIndex} callbackFn={refreshPageData}/>}
                    {/* <div className='m-b-40'>
                        <Row className='m-0 '>
                            <Col xs={12} className='conversation-header'>
                                <div className='d-flex justify-content-end flex-wrap position-relative gap-2'>
                                    <Card className={`flex-grow-1 m-r-10 top-card header-block ${selectedRef === "all" ? "selectedRef" : null} ${view?.isLoading ? `disabledClass` : ``}`} onClick={(e) => handleHeaderClicks(e, "all")} >
                                        <div className='d-flex header-container'>
                                            <span className='d-flex align-items-center icon-box'>
                                                <AllReferencesSvg />
                                            </span>
                                            <span className='w-100 p-l-10 p-r-10'>
                                                <div className="main-paragraph lightGrey mb-1">All References </div>
                                                {isHeaderLoading ? <LoadingBlock height="20px" width="auto" /> : refHeader && Object.keys(refHeader).length ? <div><b>{refHeader?.all_references_count} References</b></div> : <div className='secondaryGrey'>No references</div>}
                                            </span>
                                        </div>

                                    </Card>
                                    <Card className={`flex-grow-1 m-r-10 top-card header-block ${selectedRef === "incomplete" ? "selectedRef" : null} ${view?.isLoading ? `disabledClass` : ``} `} onClick={(e) =>  handleHeaderClicks(e, "incomplete")}>
                                        <div className='d-flex header-container'>
                                            <span className='d-flex align-items-center icon-box'>
                                                <IncompleteReferencesSvg />
                                            </span>
                                            <span className='w-100 p-l-10 p-r-10'>
                                                <div className="main-paragraph lightGrey mb-1">Your Incomplete References </div>
                                                {isHeaderLoading ? <LoadingBlock height="20px" width="auto" /> : refHeader && Object.keys(refHeader).length ? <div><b>{refHeader?.incomplete_references_count || 0} References</b></div> : <div className='secondaryGrey'>No incomplete references</div>}
                                            </span>
                                        </div>
                                    </Card>
                                    <Card className={`flex-grow-1 m-r-10 top-card header-block ${selectedRef === "trending" ? "selectedRef" : null} ${view?.isLoading ? `disabledClass` : ``} `} onClick={(e) => handleHeaderClicks(e, 'trending') }>
                                        <div className='d-flex header-container'>
                                            <span className='d-flex align-items-center icon-box'>
                                                <TrendingSvg />
                                            </span>
                                            <span className='w-100 p-l-10 p-r-10'>
                                                <div className="main-paragraph lightGrey mb-1">Trending projects this week </div>
                                                {isHeaderLoading ? <LoadingBlock height="20px" width="auto" /> : refHeader && Object.keys(refHeader).length ? <div><b>{refHeader?.trending_project?.project}</b></div> : <div className='secondaryGrey'>No trending projects</div>}
                                            </span>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </div> */}
                    {selectedRef === "all" ? <>
                        <div className='m-b-20'>
                            <Row className='m-0'>
                                <Col xs={12}>
                                    <div className='d-flex justify-content-end flex-wrap position-relative gap-2'>
                                        <Button variant='' className={`${form?.selectedDates === "today" ? "active btn-button-ref" : "not-click-ref"} ${view?.isLoading ? `disabledClass` : ``}`} onClick={() => handleReferenceClick("today")}>Today</Button>
                                        <Button variant='' className={`${form?.selectedDates === "lastWeek" ? "active btn-button-ref" : "not-click-ref"} ${view?.isLoading ? `disabledClass` : ``}`} onClick={() => handleReferenceClick("lastWeek")}>Last Week</Button>
                                        <Button variant='' className={`${form?.selectedDates === "lastMonth" ? "active btn-button-ref" : "not-click-ref"} ${view?.isLoading ? `disabledClass` : ``}`} onClick={() => handleReferenceClick("lastMonth")}>Last Month</Button>
                                        <Button variant='' className={`${form?.selectedDates === "all" ? "active btn-button-ref" : "not-click-ref"} ${view?.isLoading ? `disabledClass` : ``}`} onClick={() => handleReferenceClick("all")}>All</Button>
                                        <span className={` ${view?.isLoading ? `disabledClass` : ``}`}><RangePickerWithCustomOptions selectedDate={form?.dates} handleDateRangeChange={date => handleDateChange(date)} /></span>
                                    </div>
                                    <div className='d-flex justify-content-end flex-wrap'>
                                        {form?.errors && form?.errors["dates"] && <span className="warningText card-body-paragraph">{form.errors["dates"]}</span>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row className="m-0 ">
                            <Form className={`top-header-box p-r-20 p-l-20 m-b-30 ${view?.isLoading ? `disabledClass` : ``}`} autoComplete="off">
                                <Row>
                                    <Col xs={12}>
                                        <div className='d-flex justify-content-start flex-wrap position-relative gap-2'>
                                            <Col className='form-group'>
                                                <Form.Group className="mb-3 m-r-5 " controlId="formBasicEmail">
                                                    <Form.Label>Function:</Form.Label>
                                                    <Typeahead
                                                        labelKey="display_name"
                                                        id="dropdown"
                                                        className="typeAhead"
                                                        name={`function`}
                                                        onChange={(val) => handleDropdowns(val, `selectedFunctionArr`)}
                                                        options={dropdowns?.functions || []}
                                                        placeholder={`Select Function`}
                                                        selected={form[`selectedFunctionArr`]}
                                                        useCache={false}
                                                        multiple={true}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='form-group'>
                                                <Form.Group className="mb-3 m-r-5 " controlId="formBasicEmail">
                                                    <Form.Label>Reference Type:</Form.Label>
                                                    <Typeahead
                                                        labelKey="display_name"
                                                        id="dropdown"
                                                        className="typeAhead"
                                                        name={`function`}
                                                        onChange={(val) => handleDropdowns(val, `selectedReferenceArr`)}
                                                        options={dropdowns?.references_types || []}
                                                        placeholder={`Select Reference Type`}
                                                        selected={form[`selectedReferenceArr`]}
                                                        useCache={false}
                                                        multiple={true}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='form-group'>
                                                <Form.Group className="mb-3 m-r-5 " controlId="formBasicEmail">
                                                    <Form.Label>Ratings:</Form.Label>
                                                    <Typeahead
                                                        labelKey="display_name"
                                                        id="dropdown"
                                                        className="typeAhead"
                                                        name={`function`}
                                                        onChange={(val) => handleDropdowns(val, `selectedRatingsArr`)}
                                                        options={dropdowns?.ratings || []}
                                                        placeholder={`Select Reference Type`}
                                                        selected={form[`selectedRatingsArr`]}
                                                        useCache={false}
                                                        multiple={true}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='form-group'>
                                                <Form.Group className="mb-3 m-r-5 " controlId="formBasicEmail">
                                                    <Form.Label>Projects:</Form.Label>
                                                    <Typeahead
                                                        labelKey="display_name"
                                                        id="dropdown"
                                                        className="typeAhead"
                                                        name={`projects`}
                                                        onChange={(val) => handleDropdowns(val, `selectedProjects`)}
                                                        options={dropdowns?.projects || []}
                                                        placeholder={`Select Projects`}
                                                        selected={form[`selectedProjects`]}
                                                        useCache={false}
                                                        multiple
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='form-group'>
                                                <Form.Group className="mb-3 m-r-5 " controlId="formBasicEmail">
                                                    <Form.Label>Creator:</Form.Label>
                                                    <Typeahead
                                                        labelKey="full_name"
                                                        id="dropdown"
                                                        className="typeAhead"
                                                        name={`projects`}
                                                        onChange={(val) => handleDropdowns(val, `selectedScoperArr`)}
                                                        options={dropdowns?.creator || []}
                                                        placeholder={`Select Projects`}
                                                        selected={form[`selectedScoperArr`]}
                                                        useCache={false}
                                                        multiple
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                    </> : selectedRef === "incomplete" ? <>
                        <Row className="m-0">
                            <Col xs={12} className="m-b-20 justify-content-between d-flex">
                                <div className="main-title">Your Incomplete References</div>
                                {incompleteRef?.length && !view.isLoading  ? (<div className='d-flex'><Button variant="light" className="m-r-10 downloadBtn" onClick={openTableInNewTab} ><b>Export</b></Button><span className='ref_count d-flex align-items-center'><b>{filteredData?.isfilterApplied ? filteredData?.rows?.length : incompleteRef?.length}</b>&nbsp;results</span></div>) : null}
                            </Col>
                        </Row>
                    </> : selectedRef === "trending" ?
                        <>
                            <Row className="m-0">
                                <Col xs={12} className="m-b-20 justify-content-between d-flex ">
                                    <div className="main-title">Trending projects this week</div>
                                    {trendingReferences?.length && !view.isLoading ? (<div className='d-flex'><Button variant="light" className="m-r-10 downloadBtn" onClick={openTableInNewTab}><b>Export</b></Button><span className='ref_count d-flex align-items-center'><b>{filteredData?.isfilterApplied ? filteredData?.rows?.length : trendingReferences?.length}</b> &nbsp;results</span></div>) : null}
                                </Col>
                            </Row>
                        </> : null}
                    {view.isLoading ? <LoaderTable /> : view?.isError ? <CommonError errorMessage={view?.errorMessage} isBlurredRequired={true} /> : rowData === null ? <NoFilter message={null} /> : <>
                        <Row className="m-b-20">
                            {(selectedRef == "all") && (<Col xs={12} >
                                <div className='d-flex justify-content-between m-b-10'>
                                    <span className='main-paragraph'><b>{selectedRef == "all" ? "All References" : selectedRef == "trending" ? "Trending this week" : null}</b></span>
                                    {refSearchData?.length ? (<div className='d-flex'><Button variant="light" className='chat-gpt m-r-10' onClick={handleChatGPT}><b><ChatGPTSvg width="16px" height="16px" style={{marginTop: "-3px"}}/> ChatGPT</b></Button> <Button variant="light" className="m-r-10 downloadBtn" onClick={openTableInNewTab}><b>Export</b></Button><span className='ref_count d-flex align-items-center'><b>{filteredData?.isfilterApplied ? filteredData?.rows?.length : refSearchData?.length}</b>&nbsp;results</span></div>) : null}
                                </div>
                            </Col>)}
                            <Col xs={12} className="m-b-20">
                                <div style={{ }}>
                                    <ConversationTable
                                        dataSet={rowData}
                                        legends={legend}
                                        handleRowClick={(event) => handleModal(event)}
                                        handlePeoplesClicks={handlePeoplesClicks}
                                        handlefilteredRow={handledFilteredRow}
                                    />
                                </div>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </>}
                </Col>
            </Row>
        </>
    )
}
export default References;

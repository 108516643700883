import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { ReactComponent as TimeIcon } from "../../../assets/images/icon_svg/clock.svg";
import './searchhistoryslider.scss';

const formatFilters = (filters) => {
  if (!filters || typeof filters !== 'object') return '';

  return Object.entries(filters)
    .map(([key, valueArray]) => {
      if (key === 'tp_owner' && Array.isArray(valueArray)) {
        return `TP Owner (${valueArray.join(', ')})`;
      }

      if (Array.isArray(valueArray)) {
        return valueArray
          .map((filter) => {
            if (filter.range) {
              return `${key.charAt(0).toUpperCase() + key.slice(1)} filter (${filter.range})`;
            }
            if (filter.field) {
              return `${key.charAt(0).toUpperCase() + key.slice(1)} (${filter.field})`;
            }
            return '';
          })
          .filter(Boolean)
          .join(', ');
      }
      return '';
    })
    .filter(Boolean)
    .join('; ');
};

const formatHistoryTime = (originalTimestamp) => {
    const date = new Date(originalTimestamp);
    const day = date.getDate();
    const dayWithSuffix = day + getDaySuffix(day);

    const formattedDate = date.toLocaleDateString("en-GB", { month: "short", year: "numeric" });

    const formattedTime = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
    });

    return `${dayWithSuffix} ${formattedDate} • ${formattedTime}`;
}

function getDaySuffix(day) {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}

const SearchHistorySlider = ({ isOpen, onClose, searchHistory }) => {
  return (
    <Offcanvas show={isOpen} onHide={onClose} placement="end" style={{ width: '40pc' }} className="search-history-slider">
      <Offcanvas.Header closeButton>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Offcanvas.Title className="search-history-title">Search History</Offcanvas.Title>
        {searchHistory && searchHistory.search_history?.length > 0 ? (
          searchHistory.search_history.map((entry, index) => (
            <div key={index} className="search-history-block">
                <div className="time-icon-line"></div>
              <div className="icon-container">
                <TimeIcon/>
              </div>
              <h5 className="search-history-time">{formatHistoryTime(entry.filters_applied)}</h5>
              {entry.filters_added && (
                <div className="filters-section">
                  <span className="filters-header">Filters Added:</span> 
                  <span className="filters-content">{formatFilters(entry.filters_added)}</span>
                </div>
              )}
              {entry.filters_removed && (
                <div className="filters-section">
                  <span className="filters-header">Filters Removed:</span> 
                  <span className="filters-content">{formatFilters(entry.filters_removed)}</span>
                </div>
              )}
              {entry.filters_change && (
                <div className="filters-section">
                  <span className="filters-header">Filters Changed:</span> 
                  <span className="filters-content">{formatFilters(entry.filters_change)}</span>
                </div>
              )}
              {entry.search && (
                <div className="filters-section">
                  <span className="filters-header">Search:</span> 
                  <span className="filters-content">{entry.search}</span>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="no-history">No search history available.</div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SearchHistorySlider;

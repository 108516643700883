//Auth related actions
export const GET_DASHBOARD_DETAILS = "GET_DASHBOARD_DETAILS";
export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_TOKEN_REFRESHED = "SET_TOKEN_REFRESHED";
export const IS_REFRESH_TOKEN_VALID = "IS_REFRESH_TOKEN_VALID";
//Reference related actions
export const GET_REFERENCES = "GET_REFERENCES";
export const SET_REFERENCES_SEARCH = "SET_REFERENCES_SEARCH";
export const SET_ALL_REFERENCES = "SET_ALL_REFERENCES";
export const SET_TRENDING_REFERENCES = "SET_TRENDING_REFERENCES";
export const SET_PROJECTS = "SET_PROJECTS";
export const RESET_REFERENCES = "RESET_REFERENCES";
export const SET_SINGLE_REFERENCE = "SET_SINGLE_REFERENCE";
export const PICKER_OPEN_STATE = "PICKER_OPEN_STATE"
export const SET_FUNCTIONS = "SET_FUNCTIONS";
export const SET_REF_HEADER = "SET_REF_HEADER";
export const SET_INCOMPLETE_REF = "SET_INCOMPLETE_REF";
export const UPDATE_REFERENCE = "UPDATE_REFERENCE";
export const UPDATE_REFERENCE_ROW = "UPDATE_REFERENCE_ROW";
export const ADD_PERSON_TO_REF = "ADD_PERSON_TO_REF";

//InvestorReference related actions
export const GET_INVESTOR = "GET_INVESTOR";
export const SET_INVESTOR_SEARCH = "SET_INVESTOR_SEARCH";
//Scopers related actions
export const SET_SCOPERS = "SET_SCOPERS";


// File Matrics related actions
export const SET_FILE_MATRICS_TEMPLATE = "SET_FILE_MATRICS_TEMPLATE";
export const RESET_FILE_MATRICS_TEMPLATE = "RESET_FILE_MATRICS_TEMPLATE";
export const SET_FILE_MATRICS = "SET_FILE_MATRICS";
export const SET_COOKIE_STORE = "SET_COOKIE_STORE";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const SET_GRAPH_OPTION = "SET_GRAPH_OPTION";
export const SET_TABLE = "SET_TABLE";
export const RESET_TABLE = "RESET_TABLE";
export const SET_MORE_CALL_DATA = "SET_MORE_CALL_DATA";
export const RESET_MORE_CALL_DATA = "RESET_MORE_CALL_DATA";
export const REMOVE_MORE_CALL_DATA = "REMOVE_MORE_CALL_DATA";
export const UPDATE_MORE_CALL_DATA = "UPDATE_MORE_CALL_DATA";
export const RESET_CALLS = "RESET_CALLS";
export const DRAFT_LIST = "DRAFT_LIST";
export const DRAFT_DETAIL = "DRAFT_DETAIL";
export const UPDATE_DRAFT_LIST = "UPDATE_DRAFT_LIST";
export const RESET_DRAFT_DETAIL = "RESET_DRAFT_DETAIL";


// Dashboard Action
export const SET_TP_PIPELINE = "SET_TP_PIPELINE";
export const SET_LOGGED_IN_USER_INFO = "SET_LOGGED_IN_USER_INFO";
export const DASHBOARD_INSIGHTS_CARD = "DASHBOARD_INSIGHTS_CARD";
export const DASHBOARD_PIPELINE_MANAGEMENT = "DASHBOARD_PIPELINE_MANAGEMENT";
export const DASHBOARD_PIPELINE_MANAGEMENT_VIEWALL_LINK ="DASHBOARD_PIPELINE_MANAGEMENT_VIEWALL_LINK";
// Dashboard company update actions
export const COMPANY_UPDATE = "COMPANY_UPDATE";
export const COMPANY_FILTER_UPDATE = "COMPANY_FILTER_UPDATE";
export const COMPANY_UPDATE_TYPE = "COMPANY_UPDATE_TYPE";
export const SET_EMPLOYEE_POPULATION = "SET_EMPLOYEE_POPULATION";

export const COMPANY_PROFILE_FAVORITE = "COMPANY_PROFILE_FAVORITE";

//Getemailhistory related actions
export const GET_EMAIL_HISTORY = "GET_EMAIL_HISTORY";

//Set email error
export const SET_EMAIL_ERROR = "SET_EMAIL_ERROR";
//Gettpowner related actions
export const GET_TP_OWNER = "GET_TP_OWNER";
//Gettpstatus related actions
export const GET_TP_STATUS = "GET_TP_STATUS";

//Company related actions
export const COMPANY_PROFILE_FILE_UPLOADS = "COMPANY_PROFILE_FILE_UPLOADS";
export const COMPANY_PROFILE_DOCUMENT_TYPES = "COMPANY_PROFILE_DOCUMENT_TYPES";
export const COMPANY_PROFILE_ALERT = "COMPANY_PROFILE_ALERT";
export const COMPANY_PROFILE_FILE_UPLOADED = "COMPANY_PROFILE_FILE_UPLOADED";
export const COMPANY_UPDATE_LOADING = "COMPANY_UPDATE_LOADING";
export const COMPANY_PROFILE_FAVOURITE = "COMPANY_PROFILE_FAVOURITE";
export const COMPANY_PROFILE_LOADING = "COMPANY_PROFILE_LOADING" ;
export const COMPANY_PAGE_DETAILS = "COMPANY_PAGE_DETAILS";
export const COMPANY_PAGE_MEETING_STAT = "COMPANY_PAGE_MEETING_STAT";
export const COMPANY_PAGE_TP_STATUS_HISTORY = "COMPANY_PAGE_TP_STATUS_HISTORY"
export const COMPANY_PAGE_TP_STATUS_OPTIONS = "COMPANY_PAGE_TP_STATUS_OPTIONS"
export const COMPANY_PAGE_PIPELINE_TP_OWNER ="COMPANY_PAGE_PIPELINE_TP_OWNER"
export const COMPANY_PROFILE_PROJECT ="COMPANY_PROFILE_PROJECT";
export const COMPANY_ADD_TP_OWNER ="COMPANY_ADD_TP_OWNER";
export const COMPANY_ADD_TP_STATUS="COMPANY_ADD_TP_STATUS";
export const COMPANY_EMAIL_CHART ="COMPANY_EMAIL_CHART";
export const COMPANY_EMAIL_DETAILS ="COMPANY_EMAIL_DETAILS";
export const GET_SELECTED_LIST ="GET_SELECTED_LIST";
export const GET_AFFINITY_LIST ="GET_AFFINITY_LIST";
export const UPDATE_AFFINITY_LIST ="UPDATE_AFFINITY_LIST";


// Discovery Action
export const SET_SOURCES = "SET_SOURCES";
export const GET_COMBINED_METRICS = "GET_COMBINED_METRICS";
export const SET_FILTER_METRICS = "SET_FILTER_METRICS";
export const SET_FILTER_METRICS_LEGENDS = "SET_FILTER_METRICS_LEGENDS";
export const RESET_FILTER = "RESET_FILTER";
export const SET_COMBINED_METRICS = "SET_COMBINED_METRICS";
export const SET_LINKEDIN_CITIES = "SET_LINKEDIN_CITIES";
export const SET_LINKEDIN_STATES = "SET_LINKEDIN_STATES";
export const SET_COMPANY_ROUTE_DATA = "SET_COMPANY_ROUTE_DATA";
export const SET_TP_OWNER = "SET_TP_OWNER";
export const SET_TP_STATUS = "SET_TP_STATUS";
export const SET_TP_FILTER_OPS = "SET_TP_FILTER_OPS";
export const SET_APPLIED_COLUMN = "SET_APPLIED_COLUMN";
export const DISCOVERY_TP_STATUS_OPTIONS = "DISCOVERY_TP_STATUS_OPTIONS";
export const SET_DISCOVERY_COMPANY = "SET_DISCOVERY_COMPANY";
export const SET_DISCOVERY_TP_OWNER = "SET_DISCOVERY_TP_OWNER";
export const SET_FILTERED_DATA = "SET_FILTERED_DATA";
export const GET_DISCOVERY_METADATA = "GET_DISCOVERY_METADATA";
export const SET_DISCOVERY_FILTERS_PAYLOAD = 'SET_DISCOVERY_FILTERS_PAYLOAD';
export const RESET_DISCOVERY_FILTERS = "RESET_DISCOVERY_FILTERS";
export const SET_LLM_FILTERS = "SET_LLM_FILTERS";
export const RESET_LLM_FILTERS = "RESET_LLM_FILTERS"
export const SET_DISCOVERY_WATCH_STATUS = "SET_DISCOVERY_WATCH_STATUS"
export const SET_URL_DISCOVERY_PARAMS = "SET_URL_DISCOVERY_PARAMS"
export const SET_DISCOVERY_SEARCH_HISTORY = "SET_DISCOVERY_SEARCH_HISTORY"

// Discovery Slider actions
export const COMMON_LISTS_BUCKETS_OPTIONS = "COMMON_LISTS_BUCKETS_OPTIONS";
export const COMMON_TP_OWNER_OPTIONS = "COMMON_TP_OWNER_OPTIONS";
export const COMMON_TP_STATUS_OPTIONS = "COMMON_TP_STATUS_OPTIONS";
export const GET_COMPANY_LISTS = "GET_COMPANY_LISTS";
export const GET_COMPANY_NO_CACHED_DATA = "GET_COMPANY_NO_CACHED_DATA";
export const GET_COMPANY_CACHED_DATA = "GET_COMPANY_CACHED_DATA";
export const GET_FOUNDER_DATA = "GET_FOUNDER_DATA";

// common
export const SET_NOTE_TEMPLATE = "SET_NOTE_TEMPLATE";

// File Matrics Slider related actions
export const SET_FILE_MATRICS_TEMPLATE_SLIDER = "SET_FILE_MATRICS_TEMPLATE_SLIDER";
export const RESET_FILE_MATRICS_TEMPLATE_SLIDER = "RESET_FILE_MATRICS_TEMPLATE_SLIDER";
export const SET_FILE_MATRICS_SLIDER = "SET_FILE_MATRICS_SLIDER";
export const SET_COOKIE_STORE_SLIDER = "SET_COOKIE_STORE_SLIDER";
export const SET_TEMPLATES_SLIDER = "SET_TEMPLATES_SLIDER";
export const SET_GRAPH_OPTION_SLIDER = "SET_GRAPH_OPTION_SLIDER";
export const SET_TABLE_SLIDER = "SET_TABLE_SLIDER";
export const RESET_TABLE_SLIDER = "RESET_TABLE_";
export const SET_MORE_CALL_DATA_SLIDER = "SET_MORE_CALL_DATA_";
export const RESET_MORE_CALL_DATA_SLIDER = "RESET_MORE_CALL_DATA_SLIDER";
export const REMOVE_MORE_CALL_DATA_SLIDER = "REMOVE_MORE_CALL_DATA_SLIDER";
export const UPDATE_MORE_CALL_DATA_SLIDER = "UPDATE_MORE_CALL_DATA_";
export const RESET_CALLS_SLIDER = "RESET_CALLS_";
export const DRAFT_LIST_SLIDER = "DRAFT_LIST_SLIDER";
export const DRAFT_DETAIL_SLIDER = "DRAFT_DETAIL_";
export const UPDATE_DRAFT_LIST_SLIDER = "UPDATE_DRAFT_LIST_SLIDER";
export const RESET_DRAFT_LIST_SLIDER = "RESET_DRAFT_LIST_SLIDER";
export const RESET_DRAFT_DETAIL_SLIDER = "RESET_DRAFT_DETAIL_SLIDER";
export const SET_SLIDER_TOGGLE = "SET_SLIDER_TOGGLE";

//Side Panel Action
export const SET_SUB_ACTION_ITEM_SUBMISSIONS = "SET_SUB_ACTION_ITEM_SUBMISSIONS"
export const SET_WORKBOARD_ITEM              = "SET_WORKBOARD_ITEM"

//Workboard Action
export const SET_UPLOAD_PERCENTAGE = "SET_UPLOAD_PERCENTAGE"
export const SET_UPLOAD_CONTENT = "SET_UPLOAD_CONTENT"
export const SET_UPLOADED_FILE = "SET_UPLOADED_FILE"
export const SET_MY_WORKBOARDS = "SET_MY_WORKBOARDS"
export const SET_UPGRADED_WORKBOARDS = "SET_UPGRADED_WORKBOARDS"
export const SET_COMPANIES_WORKBOARD = "SET_COMPANIES_WORKBOARD"
export const SET_CATEGORIES_WORKBOARD= "SET_CATEGORIES_WORKBOARD"
export const SET_PEOPLE_WORKBOARD = "SET_PEOPLE_WORKBOARD"
export const SET_WORKBOARD_DATA_TEMPLATES = "SET_WORKBOARD_DATA_TEMPLATES"
export const SET_WORKBOARD_COMPANIES = "SET_WORKBOARD_COMPANIES";
export const SET_WORKBOARDS = "SET_WORKBOARDS"
export const SET_WORKBOARDS_COMPANY = "SET_WORKBOARDS_COMPANY"
export const SET_WORKBOARDS_FILTERS = "SET_WORKBOARDS_FILTERS"
export const UPDATE_MY_WORKBOARDS = "UPDATE_MY_WORKBOARDS"

//Mentions Action
export const SET_REFERENCE_DATA = "SET_REFERENCE_DATA";

//Investor Reminders Action
export const SET_INVESTOR_REMINDERS = "SET_INVESTOR_REMINDERS";
export const SET_INVESTOR_REMINDER_STATUS = "SET_INVESTOR_REMINDER_STATUS";
export const SET_URL_REMINDER_PARAMS = 'SET_URL_REMINDER_PARAMS';

// Notes Action
export const SET_NOTES = "SET_NOTES";
export const SET_NOTES_DETAILS = "SET_NOTES_DETAILS";
export const RESET_NOTES_DETAILS = "RESET_NOTES_DETAILS";
export const ASSOCIATE_FATHOM_NOTE_SUCCESS = "ASSOCIATE_FATHOM_NOTE_SUCCESS";